<template>
  <div class="think-tank-page">
    <div class="container">
      <div class="row align-items-center top-row">
        <div class="col-12 col-lg-6 pt-3 pt-lg-0 text-center text-lg-start">
          <h1 class="heading" v-html="pageTitle" />
        </div>
        <div class="col-12 col-lg-6 text-end">
          <voiceHelper
            v-if="voiceOverPath.length > 0"
            :videoPath="voiceOverPath"
          />
        </div>
      </div>
      <div class="row justify-content-center align-items-center mid-row mt-4 mt-lg-0">
        <div class="col-11 col-lg-9">
          <div class="text-container p-4">
            <div v-if="!startGame || stopGame" class="game-overlay">
              <div v-if="stopGame && this.gameComplete" class="d-flex flex-column">
                <img src="/assets/images/tick.svg" />
              </div>
              <div v-else>
                <h2 v-if="stopGame">{{game.failedMessage}}</h2>
                <div class="instructions" v-else>
                  <h2>{{heading}}</h2>
                  <p class="mb-5 px-5">{{body}}</p>
                  <p>{{game.startMessage}}</p>
                </div>
                <span v-if="countDownTimeout" class="countdown-text">{{startGameCountdown}}</span>
                <div v-else class="btn" @click="countDownTimer">
                  <span v-if="stopGame">{{game.retryButtonText}}</span>
                  <span v-else>{{game.playButtonText}}</span>
                </div>
              </div>
            </div>
            <h2>{{gameTimer}}</h2>
            <div class="game-wrapper">
              <thinkTank
                @gameUpdate="updateGameOne"
                :startGame="startGame"
                :stopGame="stopGame"
                :countdownValue="game.tankOne"
              />
              <thinkTank
                @gameUpdate="updateGameTwo"
                :startGame="startGame"
                :stopGame="stopGame"
                :countdownValue="game.tankTwo"
              />
              <thinkTank
                @gameUpdate="updateGameThree"
                :startGame="startGame"
                :stopGame="stopGame"
                :countdownValue="game.tankThree"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-between align-items-end bottom-row mt-4 mt-lg-0">
        <div class="col-4 col-lg-1">
          <img class="logo logo--small" src="/assets/images/logo.png" />
        </div>
        <div class="col-4 col-lg-10">
          <ProgressMenu :url="link" />
        </div>
        <div class="col-4 col-lg-1 text-end">
          <div v-if="showNext" ref="nextButton" class="next-btn-wrapper mb-3 me-3 d-inline-block">
            <router-link :to="link" class="next-btn">Next</router-link>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="col-12 footer-links bg--white">
          © AstraZeneca
          <span>|</span>
          <a href="https://www.astrazeneca.com/legal-notice-and-terms-of-use.html" target="_blank">Legal notice and Terms of Use</a>
          <span>|</span>
          <a href="https://www.azprivacy.astrazeneca.com/europe/unitedkingdom/en/privacy-notices.html" target="_blank">Privacy Notice</a>
          <span>|</span>
          <a href="//policy.cookiereports.com/fe01e9b6-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
          <span>|</span>          
          <a href="https://www.astrazeneca.com/our-company/Contact-us.html" target="_blank">Contact us</a>
      </div>
    </footer>
  </div>
</template>

<script>
import video from "../../components/Video.vue";
import voiceHelper from "../../components/VoiceHelper.vue";
import thinkTank from "../../components/ThinkTank.vue";
import ProgressMenu from "../../components/ProgressMenu";

export default {
  name: "page-1-3",
  components: {
    vimeoPlayer: video,
    voiceHelper,
    thinkTank,
    ProgressMenu
  },
  data() {
    return {
      gameOneFailed: false,
      gameTwoFailed: false,
      gameThreeFailed: false,
      startGame: false,
      gameTimer: this.$store.getters.content["page-1-4"].game.gameTimer,
      startGameCountdown: 3,
      countDownTimeout: null,
      gameCountDownTimeout: null,
      stopGame: false,
      retry: false,
      showNext: false
    };
  },
  computed: {
    pageTitle() {
      return this.$store.getters.content["page-1-4"].title;
    },
    voiceOverPath() {
      return this.$store.getters.content["page-1-4"].voiceOverFileName;
    },
    link() {
      return this.$store.getters.content["page-1-4"].link;
    },
    game() {
      return this.$store.getters.content["page-1-4"].game;
    },
    heading() {
      return this.$store.getters.content["page-1-4"].heading;
    },
    body() {
      return this.$store.getters.content["page-1-4"].body;
    },
    gameComplete() {
      return !this.gameOneFailed && !this.gameTwoFailed && !this.gameThreeFailed;
    },
  },
  methods: {
    updateGameOne(status) {
      this.gameOneFailed = status;
    },
    updateGameTwo(status) {
      this.gameTwoFailed = status;
    },
    updateGameThree(status) {
      this.gameThreeFailed = status;
    },
    countDownTimer() {
      if(this.startGameCountdown === 0) {
        clearTimeout(this.countDownTimeout);
        this.startGame = true;
        this.gameCountDownTimer()
      } else {
        this.startGame = false;
        this.countDownTimeout = setTimeout(() => {
            this.startGameCountdown -= 1
            this.countDownTimer()
        }, 1000)
      }
    },
    gameCountDownTimer() {
      if(this.gameTimer === 0) {
        this.showNext = true;

        this.stopGame = true;
        clearTimeout(this.gameCountDownTimeout);
        if(!this.gameComplete) {
          this.countDownTimeout = null;
          this.startGameCountdown = 3
          this.gameTimer = this.$store.getters.content["page-1-4"].game.gameTimer;
        }

      } else {
        this.stopGame = false;
        this.gameCountDownTimeout = setTimeout(() => {
            this.gameTimer -= 1
            this.gameCountDownTimer()
        }, 1000)
      }
    },
  },
};
</script>
