<template>
  <div class="think-tank">
    <div class="tank__wrapper d-flex flex-column">
      <div class="tank">
        <div class="water" :style="`height: ${progressHeight}`" />
        <div :class="`red-zone ${dangerZoneActive ? 'alert' : ''}`">Red Zone</div>
      </div>
      <button class="tank-btn" @click="addTime">Add Supplies</button>
    </div>
    <div class="pipe" />
  </div>
</template>

<script>
  export default {
    name: "thinkTank",
    props: ['countdownValue', 'startGame', 'stopGame'],
    data () {
      return {
        timer: this.countdownValue,
        countDown: this.countdownValue,
        isCounting: false,
        dangerZoneActive: false,
        countDownTimeout: null,
        speedArray: [1000, 1500, 2000]
      }
    },
    computed: {
      progressHeight() {
        return this.countDown / this.timer * 100 + '%'
      },
    },
    methods: {
      countDownTimer() {
        if (this.countDown > 0) {
          this.isCounting = true
          this.countDownTimeout = setTimeout(() => {
              this.countDown -= 1
              this.countDownTimer()
          }, this.speedArray[Math.floor(Math.random() * 3) + 1])
        } else {
          this.isCounting = false
        }
      },
      stopTimer() {
        if(this.countDownTimeout){
          clearTimeout(this.countDownTimeout);
        }
      },
      addTime() {
        if(this.stopGame) return
        if(this.countDown < this.timer) {
          this.countDown = this.countDown + this.timer / 10 * 2
          if(!this.isCounting) {
            this.countDownTimer()
          }
        }
      }
    },
    watch: {
      startGame(value) {
        if(value) {
          this.countDown = this.timer;
          this.countDownTimer()
        }
      },
      stopGame(value) {
        if(value) {
         this.stopTimer()
        }
      },
      countDown(value) {
        if(value > this.timer) {
          this.countDown = this.timer
        }
      },
      progressHeight(value) {
        const percentage = value.replace(/%/g, '')
        const percentageNumber = Number(percentage);

        if(percentageNumber <= 15) {
          this.dangerZoneActive = true
          this.$emit('gameUpdate', true)
        } else {
          this.dangerZoneActive = false
          this.$emit('gameUpdate', false)
        }
      }
    }
  }
</script>
